<template>
  <v-form @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <BaseInput
          v-model.trim="password"
          :append-icon="show ? svgEye : svgEyeOff"
          :label="$t('board.encryption_password')"
          :type="show ? 'text' : 'password'"
          outlined
          dense
          :validations="$v"
          name="password"
          autocomplete="false"
          @click:append="show = !show"
        ></BaseInput>
      </v-col>
      <v-col v-if="confirm" cols="12">
        <BaseInput
          v-model.trim="confirmPassword"
          :append-icon="showConfirm ? svgEye : svgEyeOff"
          :label="$t('common.confirm_password')"
          :type="showConfirm ? 'text' : 'password'"
          outlined
          dense
          :validations="$v"
          name="confirmPassword"
          autocomplete="false"
          @click:append="showConfirm = !showConfirm"
        ></BaseInput>
      </v-col>
      <v-col cols="12" class="text-right">
        <BaseButton outlined color="gray" @click="submitCancel">
          {{ $t("common.cancel") }}
        </BaseButton>
        <BaseButton class="ml-2 btn--encrypt" type="submit">
          <span class="btn__content">{{ $t("common.ok") }}</span>
          <span class="btn__loader">
            <v-overlay
              color="transparent"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'overlay__progress-mobile'
                  : confirm
                  ? 'overlay__progress-confirm'
                  : 'overlay__progress'
              "
            >
              <Progress />
            </v-overlay>
          </span>
        </BaseButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mdiEyeOff, mdiEye } from "@mdi/js";

export default {
  name: "BoardEncryptionForm",
  inject: ["agree", "cancel"],
  props: {
    confirm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      show: false,
      showConfirm: false,
      svgEyeOff: mdiEyeOff,
      svgEye: mdiEye
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.agree(this.password);
      this._clear();
    },
    submitCancel() {
      this.cancel();
      this._clear();
    },
    _clear() {
      this.$v.$reset();
      this.password = "";
      this.confirmPassword = "";
    }
  },
  validations() {
    return {
      password: { required, minLength: minLength(3) },
      confirmPassword: this.confirm
        ? { required, same_as_password: sameAs("password") }
        : {}
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn--encrypt {
  .btn__loader {
    visibility: hidden;
    position: absolute;
  }

  &:active {
    .btn__loader {
      visibility: visible;
    }
  }
}
::v-deep .overlay__progress-confirm {
  .v-overlay__content {
    width: 475px;
    background-color: white;
    padding-bottom: 125px;
    margin-top: 50px;
  }
}
::v-deep .overlay__progress {
  .v-overlay__content {
    width: 475px;
    background-color: white;
    padding-bottom: 50px;
    margin-top: 50px;
  }
}
::v-deep .overlay__progress-mobile {
  .v-overlay__content {
    position: absolute;
    width: 100vw;
    top: 0;
    background-color: white;
    padding-bottom: 50vh;
    margin-top: 50px;
  }
}
</style>
